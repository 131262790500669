<template>
  <component :is="roleData === undefined ? 'div' : 'b-card'">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="roleData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching role data
      </h4>
      <div class="alert-body">
        No user found with this role id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-users-list'}"
        >
          Role List
        </b-link>
        for other roles.
      </div>
    </b-alert>

    <!-- Form -->
    <div
      class="tabs-container"
    >
      <b-tabs
        lazy
        class="tab-action-btn-fill-container"
        justified
      >
        <!-- Tab: Permissions -->
        <b-tab
          active
        >
          <template #title>
            <feather-icon
              icon="UserIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Role</span>
          </template>

          <hr>

          <b-row>
            <!-- Field: name -->
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                label="Role Name"
                label-for="role_name"
              >
                <b-form-input
                  id="role_name"
                  v-model="roleData.name"
                  :state="formValidation.name.state"
                  placeholder="Role name"
                />
                <b-form-invalid-feedback>
                  {{ formValidation.name.message }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <!-- Field: company_name -->
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                label="Company Name"
                label-for="company_name"
              >
                <b-form-input
                  id="company_name"
                  v-model="roleData.company_name"
                  disabled
                />
              </b-form-group>
            </b-col>

            <!-- Field: description -->
            <b-col
              cols="12"
            >
              <b-form-group
                label="Description"
                label-for="description"
              >
                <b-form-textarea
                  id="description"
                  v-model="roleData.description"
                  rows="1"
                  class="w-full"
                  placeholder="Describe what the role can do"
                  maxlength="80"
                  :state="formValidation.description.state"
                />

                <b-form-invalid-feedback>
                  {{ formValidation.description.message }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>

          <!-- Field: permissions -->
          <permissions-card
            v-if="renderPermissionsCard && roleData.permissions != null"
            :user-permissions="roleData.permissions"
            @change="handlePermissions"
          />
        </b-tab>
      </b-tabs>
    </div>

    <!-- Action Buttons -->
    <div class="d-flex flex-row-reverse my-2">
      <ButtonSpinner :is-loading="isButtonLoading.all">
        <b-button
          v-if="$route.name === 'roles-edit'"
          class="mx-1 view-profile"
          variant="primary"
          @click="viewRole"
        >View Role</b-button>
      </ButtonSpinner>

      <ButtonSpinner :is-loading="isButtonLoading.all">
        <b-button
          variant="warning"
          class="mx-1 click-save"
          :disabled="
            !formValidation.name.state
              || !formValidation.description.state
          "
          @click="saveRole"
        >Save Role</b-button>
      </ButtonSpinner>
    </div>
  </component>
</template>

<script>
import {
  BAlert,
  BAvatar,
  BButton,
  BCard,
  BCardHeader,
  BCardTitle,
  BCol,
  BForm,
  BFormGroup,
  BFormCheckbox,
  BFormInput,
  BFormInvalidFeedback,
  BFormValidFeedback,
  BImg,
  BLink,
  BMedia,
  BRow,
  BTab,
  BTabs,
  BTable,
} from 'bootstrap-vue'
import VSelect from 'vue-select'

import PermissionsCard from '@/views/user-management-system/components/PermissionsCard.vue'

export default {
  components: {
    BAlert,
    BAvatar,
    BButton,
    BCard,
    BCardHeader,
    BCardTitle,
    BCol,
    BForm,
    BFormGroup,
    BFormCheckbox,
    BFormInput,
    BFormInvalidFeedback,
    BFormValidFeedback,
    BImg,
    BLink,
    BMedia,
    BRow,
    BTab,
    BTabs,
    BTable,
    VSelect,

    PermissionsCard,
  },

  props: {
    formType: {
      type: String,
      default: 'create',
    },
  },

  data() {
    return {
      isLoading: false,
      isButtonLoading: {
        all: false,
      },
      renderPermissionsCard: true,
      roleData: {
        permissions: [],
      },
      userData: {},

      formValidation: {
        name: {},
        description: {},
      },
    }
  },
  computed: {
  },
  setup() {
    const perm_rights = {}

    const permissionsData = [
      {
        module: 'Analytics',
        read: true,
        write: true,
        create: true,
        delete: true,
      },
      {
        module: 'Data',
        read: true,
        write: true,
        create: true,
        delete: true,
      },
      {
        module: 'User',
        read: true,
        write: true,
        create: true,
        delete: true,
      },
      {
        module: 'Audit',
        read: true,
        write: true,
        create: true,
        delete: true,
      },
      {
        module: 'Events',
        read: true,
        write: true,
        create: true,
        delete: true,
      },
      {
        module: 'Agencies Directory',
        read: true,
        write: true,
        create: true,
        delete: true,
      },
      {
        module: 'Finance',
        read: true,
        write: true,
        create: true,
        delete: true,
      },

      {
        module: 'View NRIC Number',
        read: true,
        write: true,
        create: true,
        delete: true,
      },
      {
        module: 'Download User Data',
        read: true,
        write: true,
        create: true,
        delete: true,
      },
    ]

    return {
      perm_rights,

      permissionsData,
    }
  },
  watch: {
    'roleData.name': async function (val, oldVal) {
      this.validateRoleForm()
    },
    'roleData.description': async function (val, oldVal) {
      this.validateRoleForm()
    },
    'roleData.permissions': async function (val, oldVal) {
      await this.$set(this.roleData, 'permissions', val)
      // this.renderPermissions = false
      // setTimeout(() => {
      //   this.renderPermissions = true
      // }, 100);
    },
  },
  created() {
    this.roleData.company_id = this.$store.state.auth.ActiveUser.company_id
    this.roleData.company_name = this.$store.state.auth.ActiveUser.company_name

    if (this.$route.name === 'roles-edit') {
      this.get_role()
        .then(() => {
          this.$set(this, 'renderPermissionsCard', true)
        })
    }
  },
  mounted() {
    this.validateRoleForm()
  },
  methods: {
    validateRoleForm() {
      if (this.$isNotEmpty(this.roleData.name)) {
        this.formValidation.name = this.$validateGeneralAcceptedString(this.roleData.name)
      } else {
        this.formValidation.name.state = false
        this.formValidation.name.message = 'Name is required'
      }

      this.formValidation.description = this.$validateGeneralAcceptedString(this.roleData.description)
    },
    get_role() {
      return new Promise((resolve, reject) => {
        const { id } = this.$route.params

        this.isLoading = true
        this.$http
          .get(`/api/roles/${id}`)
          .then(response => {
            this.$set(this, 'roleData', {})
            this.$set(this, 'roleData', response.data)
            // this.$set(this.roleData, 'permissions', [])
            this.$set(this.roleData, 'permissions', response.data.permissions)
            this.$set(this, 'renderPermissionsCard', false)

            if (this.$isEmpty(this.roleData)) {
              this.$router.push({ name: 'roles-create' })
            }

            setTimeout(() => {
              resolve()
            }, 200)
          })
          .catch(error => {
            this.$handleErrorResponse(error, 'Operation Failed', response.data.message)

            this.isLoading = false
            reject()
          })
      })
    },
    post_role() {
      this.isLoading = true
      this.isButtonLoading.all = true
      this.$http
        .post('/api/roles', {
          form_data: this.roleData,
        })
        .then(response => {
          const { output, success, message } = response.data
          if (success) {
            this.$toastSuccess('Operation Status', 'Role created successfully!')

            this.$router.push({ name: 'roles-view', params: { id: output.id } })
          } else {
            this.$toastDanger('Operation Status', message)
          }
          this.isLoading = false
          this.isButtonLoading.all = false
        })
        .catch(error => {
          this.isLoading = false
          this.isButtonLoading.all = false
          this.$handleErrorResponse(error)
        })
    },
    put_role() {
      this.isLoading = true
      this.isButtonLoading.all = true
      this.$http
        .put(`/api/roles/${this.roleData.id}`, {
          form_data: this.roleData,
        })
        .then(response => {
          const { output, success, message } = response.data

          if (success) {
            this.$toastSuccess('Operation Status', 'Role updated successfully!')

            this.$router.push({ name: 'roles-view', params: { id: output.id } })
          } else {
            this.$toastDanger('Operation Status', message)
          }
          this.isLoading = false
          this.isButtonLoading.all = false
        })
        .catch(error => {
          this.isLoading = false
          this.isButtonLoading.all = false
          this.$handleErrorResponse(error)
        })
    },
    get_user() {
      const { email } = this.$store.state.auth.ActiveUser

      this.$http
        .get(`/api/users/${email}`)
        .then(response => {
          this.userData = response.data
        })
        .catch(error => {
          this.$handleErrorResponse(error)

          this.isLoading = false
        })
    },

    saveRole() {
      let proceed = true

      if (!this.checkDescription(this.roleData.description).state) {
        proceed = false
      }

      if (proceed) {
        if (this.$route.name === 'roles-edit') {
          this.put_role()
        } else {
          this.post_role()
        }
      }

      // if (this.checkPermissions().state) {
      //   this.$toast(
      //     {
      //       component: this.$toastContent,
      //       props: {
      //         title: 'Operation Failed',
      //         icon: 'CheckCircleIcon',
      //         text: this.checkPermissions()['message'],
      //         variant: 'danger',
      //       },
      //     },
      //     { timeout: this.$longestTimeout },
      //   )
      // } else {
      // }
    },
    viewRole() {
      this.$router.push({ name: 'roles-view', params: { id: this.roleData.id } })
    },

    // state and validation
    checkRoleName(name) {
      let state = true
      let message = ''

      if (!name) {
        return {
          state,
          message,
        }
      }

      if ((name.length <= 2)) { // if fullname less than 2 char
        state = false
        message = 'Role name must contain more than 2 characters'
      }

      // const format = /[!@#$%^&*()`_+\-=\[\]{};':"\\|,.<>\/?]+/
      const format = /[!@#$%^&*()`_+\-=\[\]{};':"\\|,.<>\/?0-9]+/
      if (format.test(name)) { // if contain specaial character
        state = false
        message = 'A valid role name is required (only letters allowed)'
      }

      return {
        state,
        message,
      }
    },
    checkPermissions() {
      let state = true
      let message = ''

      if (!this.roleData.permissions) {
        state = false
        message = 'Must have 1 or more permission ticked'

        return { state, message }
      }

      if (Object.keys(this.roleData.permissions).length <= 0) {
        state = false
        message = 'Must have 1 or more permission ticked'
      }

      return { state, message }
    },
    checkDescription(description) {
      let state = true
      let message = ''

      if (description == null) {
        state = false
        message = 'Description has a limit of maximum 50 character'

        return { state, message }
      }

      if (description.length > 80) {
        state = false
        message = 'Description has a limit of maximum 50 character'
      }

      return { state, message }
    },

    // permissions
    handlePermissions(permissions) {
      this.roleData.permissions = permissions
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
